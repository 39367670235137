// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Select_wrapperComponent__kuZto {\n  display: block;\n}\n.Select_wrapperComponent__kuZto.Select_isSpecial__RLL-p > div > div:first-of-type {\n  border-color: #74ff59 !important;\n}\n.Select_wrapperComponent__kuZto.Select_isSpecial__RLL-p > div > div:nth-of-type(2) {\n  top: 100% !important;\n  border-top: unset;\n}", "",{"version":3,"sources":["webpack://./src/theme/components/controls/Select/Select.module.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;AAGM;EACE,gCAAA;AADR;AAIM;EACE,oBAAA;EACA,iBAAA;AAFR","sourcesContent":[".wrapperComponent {\n  display: block;\n\n  &.isSpecial {\n    > div > div {\n      &:first-of-type {\n        border-color: #74ff59 !important;\n      }\n\n      &:nth-of-type(2) {\n        top: 100% !important;\n        border-top: unset;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapperComponent": "Select_wrapperComponent__kuZto",
	"isSpecial": "Select_isSpecial__RLL-p"
};
export default ___CSS_LOADER_EXPORT___;
